html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

#map {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;

  .nasa-copyright {
    background-color: rgba(0, 0, 0, 0.5);
    font-family: arial, sans-serif;
    font-size: 14px;
    color: white;
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: auto;
    padding: 10px;
    z-index: 999999;
    font-weight: bold;
  }

  img.imps-logo {
    max-width: 250px;
    width: 100px;
    height: auto;
    position: absolute;
    z-index: 9;
    right: 15px;
    top: 15px;
    user-select: none;
  }

  .repo-stars {
    position: absolute;
    right: 15px;
    top: 65px;
    z-index: 1;
  }
}
